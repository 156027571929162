import {
  colors,
  debounce,
  Dialog,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EmptyPage from '@assets/empty-page.svg'
import React, { useEffect, useState } from 'react';
import TextField from '@atoms/TextField';
import Button from '@atoms/Button';
import { EXTRA_COLORS } from '@utils/theme';
import { useHistory } from 'react-router-dom';
import IconFromSvg from '@src/_components/atoms/IconFromSvg';
import ScannerIcon from '@assets/scan-icon.svg';
import SaveIcon from '@assets/upload.svg';
import SettingsService from '@service/settings-service/SettingsService';

export interface IUserGroupModal {
  isOpen: boolean;
  onClose: () => void;
}

const ScannerUloadModal = (props: IUserGroupModal) => {
  const theme = useTheme();
  var history = useHistory();


  const { isOpen, onClose } = props;

  const [isWindows, setIsWindows] = useState(false);
  const [imgSrc, setImgSrc] = useState('');
  const [fileName, setFileName] = useState('');
  const [msg, setMsg] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [fileNameError, setFileNameError] = useState('');
  const [isScanning, setIsScanning] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [file, setFile] = useState<Blob | null>(null);

  // TODO
  // Do a port scan in the future because this IP is not guaranteed
  // to be available on all client machines
  const SCANNER_INTERFACE_ENDPOINT = 'http://localhost:30303';


  useEffect(() => {
    const checkPlatform = () => {
      const platform = navigator.platform.toLowerCase();
      setIsWindows(platform.includes('win'));
    };

    checkPlatform();
  }, []);

  useEffect(() => {
    const now = new Date();
    const defaultFileName = `${now.toLocaleDateString()?.split('/').join('-')}-${now.toLocaleTimeString()?.split(':').join('-').split(' ').join('-')}-scan.jpg`;
    setFileName(defaultFileName);
  }, []);

  useEffect(() => {
    validateFileName();
  }, [fileName]);

  useEffect(() => {
    fetch(`${SCANNER_INTERFACE_ENDPOINT}/api/hello`)
      .then((r) => r.json())
      .then(d => {
        console.log('Sonet service to TWAIN scanner is running!');
      }).catch(err => {
        console.error(err);
        setErrorMessage('Sonet service to connect to the scanner is not running!');
      });
  }, []);

  useEffect(() => {
    resetAll();
  }, [isOpen]);


  const resetAll = () => {
    setImgSrc('');
    setMsg('');
    setErrorMessage('');
    setFileNameError('');
    setIsScanning(false);
    setFile(null);

  }

  const handleInputChange = (event: any) => {

  };

  const validateFileName = () => {
    setFileNameError('');
    if (!(fileName.endsWith('.jpg') || fileName.endsWith('.png') || fileName.endsWith('.jpeg'))) {
      setFileNameError('File name has to end with one of .jpg, .png or .jpeg');
      return false;
    }

    if (fileName.includes(':') || fileName.includes('*') || fileName.includes('?') || fileName.includes('/') || fileName.includes('\\')) {
      setFileNameError('Special characters (:, *, ?, ", /) are not allowed in the file name');
      return false
    }
    setFileNameError('');
    return true;
  };

  const checkFieldValidation = () => {

  };

  const handleScan = () => {
    resetAll();
    setIsScanning(true);
    fetch(`${SCANNER_INTERFACE_ENDPOINT}/api/preview`)
      .then(res => {
        console.log('RESPONSE', res);
        if (!res.ok) {
          setMsg('Image Scan Preview Failed!')
          throw new Error('Image Scan Preview Failed!');
        }
        return res.blob();
      }).then(blob => {
        const imgURL = URL.createObjectURL(blob);
        setImgSrc(imgURL);
        setMsg('Image Scan Preview Successful!');
        setErrorMessage('');
        setFile(blob);
      }).catch(err => {
        setErrorMessage('Image Scan Preview Failed!');
        console.error(err);
      }).finally(() => {
        setIsScanning(false);
      });
  }

  const handleSave = async () => {
    if (!validateFileName()) {
      return;
    }

    if (!file) {
      setErrorMessage("No image scanned. Please scan an image first.");
      return;
    }

    setIsUploading(true);
    const formData = new FormData();
    formData.append('fileName', fileName);
    formData.append('file', file);

    SettingsService.postFileUpload(formData)
      .then(r => {
        setMsg(`${fileName} is saved in the cloud. You can find it under MyDrive.`)
      }).catch((e: any) => {
        setErrorMessage("Failed to upload the the file. :" + e?.messsage);
      }).finally(() => {
        setIsUploading(false);
      });
  };

  const shouldSaveBeDisabled = () => {

  };

  if (!isWindows) {
    return (
      <Dialog open={isOpen} onClose={onClose}>
        <Grid
          container
          sx={{
            pl: theme.spacing(6),
            pr: theme.spacing(6),
            pt: theme.spacing(6),
            pb: theme.spacing(6),
            boxShadow: ' 0px 10px 10px rgba(0, 0, 0, 0.06)',
            position: 'relative'
          }}
          direction="column"
          alignItems="center"
          justifyContent="space-evenly"
        >
          <Grid container justifyContent="flex-end" sx={{ position: 'relative' }}>
            <Stack direction="column">
              <Typography variant="h3">{'Use Windows device to control your scanner.'}</Typography>
              <Typography
                sx={{ color: theme.palette.text.disabled, pt: theme.spacing(4) }}
                variant="body2"
              >
                {'Scanner support is currently available exclusively on the Windows platform. We are gradually expanding this support to other platforms.'}
              </Typography>
            </Stack>
            <IconButton sx={{ position: 'absolute', right: 0 }} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Dialog>
    )
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Grid
        container
        sx={{
          pl: theme.spacing(6),
          pr: theme.spacing(6),
          pt: theme.spacing(6),
          pb: theme.spacing(6),
          boxShadow: ' 0px 10px 10px rgba(0, 0, 0, 0.06)',
          position: 'relative'
        }}
        direction="column"
        alignItems="center"
        justifyContent="space-evenly"
      >
        <Grid container justifyContent="flex-end" sx={{ position: 'relative' }}>
          <Stack direction="column">
            <Typography variant="h3">{'Scan'}</Typography>
            <Typography
              sx={{ color: theme.palette.text.disabled }}
              variant="body2"
            >
              {'Click scan to invoke your scanner and save to upload them to your MyDrive on the cloud storage.'}
            </Typography>
          </Stack>
          <IconButton sx={{ position: 'absolute', right: 0 }} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid container sx={{ pb: theme.spacing(12) }}>
          <Grid item sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', pt: theme.spacing(4), mb: theme.spacing(2) }}>
            <TextField
              value={fileName}
              inputProps={{ 'data-testid': 'fileName' }}
              onBlur={checkFieldValidation}
              required
              placeholder={'file name for scanned file'}
              label={'File Name'}
              name={'fileName'}
              error={fileNameError !== ''}
              helperText={fileNameError}
              handleChange={(event: any) => { setFileName(event.target.value); }}
              cy-data-id="name"
              textFieldSize='small'
            />
          </Grid>
          <Grid item sx={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center', fontFamily: 'inherit' }} className={`${isScanning ? 'scanning' : ''}`}  >
            {
              imgSrc
                ? <img className=" border-2 border-red-500 scanned-img rounded-lg shadow-lg overflow-hidden" src={imgSrc} alt="image preview" />
                : <img className=" border-2 border-red-500 empty-img  w-full h-auto object-contain " src={EmptyPage} alt="click scan to continue" />
            }
            {msg ? <p style={{ fontFamily: 'Manrope' }} className="success-msg animate-fadeOut w-full my-2 break-words break-all text-green-700 text-center text-sm">{msg}</p> : null}
            {errorMessage ? <p style={{ fontFamily: 'Manrope' }} className="error-msg animate-fadeOut my-2 break-words break-all text-center text-red-600 text-xs">{errorMessage}</p> : null}
          </Grid>

        </Grid>
        <Grid container
          sx={{ position: 'absolute', bottom: 0, left: 0, right: 0, py: theme.spacing(3) }}
          justifyContent='space-around'
        >
          <Button
            sx={{
              width: theme.spacing(60),
              height: theme.spacing(10),
              marginRight: 3,
              '&:hover': { backgroundColor: EXTRA_COLORS.lightBlue },
            }}
            color="primary"
            variant="outlined"
            disabled={isScanning}
            onClick={debounce(() => { handleScan() }, 500)}
            cy-data-id="cancel-btn"
            startIcon={<IconFromSvg path={ScannerIcon} alt="scan" className={`text-sonet ${isScanning ? 'animate-pulse' : ''}`} />}
          >
            {`Scan${isScanning ? 'ning...' : ''}`}
          </Button>
          <Button
            sx={{
              width: theme.spacing(60),
              height: theme.spacing(10),
              textAlign: 'center',
            }}
            color="primary"
            variant="outlined"
            startIcon={<IconFromSvg path={SaveIcon} alt="save" className="text-white h-12 w-12" />}
            disabled={!(imgSrc && fileName)}
            onClick={handleSave}
            cy-data-id="save-btn"
          >
            {'Save in MyDrive'}
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ScannerUloadModal;
